import { ICardFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import Card, { CardProps } from './Card'
import { BlockRenderer } from 'src/siteBuilder/renderer/contentful/BlockRenderer'
import { typedLowerCase } from '@/siteBuilder/utils/text/mappers'

const MAP_PADDING = { Narrow: 'narrow', Intermediate: 'intermediate', Compact: 'compact', None: 'custom' }

const ContentfulCard = withContentful<ICardFields, CardProps>(Card, {
  children: (props) => {
    return <BlockRenderer block={props.fields.components} fieldPath={'.components'} />
  },
  background: (props) => (props.fields.background === 'White' ? undefined : typedLowerCase(props.fields.background)),
  padding: (props) => MAP_PADDING[props.fields.padding],
  fullBleedImage: (props) => ({
    src: props.fields.fullBleedImage?.fields?.file?.url,
    width: props.fields.fullBleedImage?.fields?.file?.details?.image?.width,
    height: props.fields.fullBleedImage?.fields?.file?.details?.image?.height,
    alt: props.fields.fullBleedImage?.fields?.description,
  }),
  fullBleedImagePosition: (props) => {
    return {
      xs: typedLowerCase(props.fields?.xsFullBleedImagePosition),
      sm: typedLowerCase(props.fields?.smFullBleedImagePosition),
      md: typedLowerCase(props.fields?.mdFullBleedImagePosition),
      lg: typedLowerCase(props.fields?.lgFullBleedImagePosition),
      xl: typedLowerCase(props.fields?.xlFullBleedImagePosition),
    }
  },
  fullBleedContentAlignment: (props) => {
    return {
      xs: typedLowerCase(props.fields?.xsFullBleedContentAlignment),
      sm: typedLowerCase(props.fields?.smFullBleedContentAlignment),
      md: typedLowerCase(props.fields?.mdFullBleedContentAlignment),
      lg: typedLowerCase(props.fields?.lgFullBleedContentAlignment),
      xl: typedLowerCase(props.fields?.xlFullBleedContentAlignment),
    }
  },
})

export default ContentfulCard
